import React, { Component, Fragment, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { ToastContainer, toast, TypeOptions } from "react-toastify";

import 'bootstrap/dist/css/bootstrap.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
// import "primereact/resources/themes/mdc-light-indigo/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../../scss/app.scss';

import Router from './Router';
import store from './store';
import { settings } from '../../redux/actions/settingsAction'
import fetchMethodRequest from '../../config/service'
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import "react-toastify/ReactToastify.min.css";

// import fontAwsome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUsers, faTachometerAlt, faStethoscope, faPaperPlane, faUserFriends,
  faPlus, faFile, faEdit, faTrashAlt, faThumbsUp, faShoppingCart, faComments, faFemale, faMale, faDownload,
  faMoneyBillAlt, faGlobeAsia, faUser, faDollarSign, faCertificate, faSyncAlt, faUpload, faBookOpen,
  faBan, faCheckCircle, faTimesCircle, faCalendar, faTable, faInfoCircle, faBars, faHandPaper, faChevronRight, faChevronLeft, faCog, faPrint, faGripHorizontal, faList
} from '@fortawesome/free-solid-svg-icons'

// fontawsome icons library
library.add(
  faTachometerAlt,
  faUsers,
  faStethoscope,
  faChevronRight, faChevronLeft,
  faPaperPlane,
  faUserFriends,
  faPlus,
  faFile,
  faEdit,
  faTrashAlt,
  faThumbsUp,
  faShoppingCart,
  faComments,
  faDownload,
  faMoneyBillAlt,
  faGlobeAsia,
  faUser,
  faDollarSign,
  faCertificate,
  faSyncAlt,
  faUpload,
  faBookOpen, faFemale, faMale,
  faBan, faCheckCircle, faTimesCircle, faCalendar, faTable, faInfoCircle, faBars,
  faHandPaper, faCog, faPrint, faGripHorizontal, faList
)
const languageConfig = {
  "interpolation": {
    "escapeValue": false
  },
  "lng": "en",
  "resources": {
    "en": {
      "common": {}
    },
    "fr": {
      "common": {}
    },
  }
}

i18next.init(i18nextConfig);

const App = () => {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    loadSettingsData()
  }, []);

  const loadSettingsData = async () => {
    fetchMethodRequest('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        let settingsData = response.settings[0]
        await store.dispatch(settings(settingsData))
        if (settingsData && settingsData.languageResources) {
          let langKeys = Object.keys(settingsData.languageResources)
          localStorage.setItem('langKeys', JSON.stringify(langKeys));
          let lanConfig = languageConfig;
          let currentLang = localStorage.getItem('currentLang');
          currentLang = currentLang ? currentLang : 'en';

          lanConfig.lng = currentLang;
          lanConfig.resources = settingsData.languageResources;
          await i18next.init(lanConfig);
          setLoading(false);
        }
      }
    })
  }


  return (
    <Provider store={store}>
      <ToastContainer position="top-right" />
      <BrowserRouter basename="/">
        <I18nextProvider i18n={i18next}>
          <ScrollToTop>
            <Fragment>
              <div>
                <Router />
              </div>
            </Fragment>
          </ScrollToTop>
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  );

}

export default App;
