
import React, {  useState } from 'react';


import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Link,Navigate } from 'react-router-dom';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
// login styles
// import '../../../../scss/loginStyles.css';

// fecth method from service.js file
import fetch from '../../../../config/service';
import config from '../../../../config/config';
import configMessage from '../../../../config/configMessages';
import { withTranslation } from 'react-i18next';

// show message 
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import Loader from '../../../App/Loader';
import { useForm, Controller } from 'react-hook-form';
import DoPasswordField from '../../../Form/Fields/DoPasswordField';
import { getPasswordRegex } from '../../../Form/DoValidations';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from 'react-router';



//validations

let newpassval = value => (value ? config.passwordRegex.test(value) ? undefined : 'The Password should contain one Uppercase letter, one Lower case letter and a Number' : configMessage.fillField)

// config file

const ChangeRecoverPassword = (props) => { 
  
  const [reDir, setReDir] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();

  const schema = yup.object().shape({   
    newPassword: yup.string().required().matches(getPasswordRegex(),"Password must contain at least 8 characters, one uppercase, one number and one special case character"),
    confirmPassword: yup.string().required().test('passwords-match', 'New Passwords and confirm password must match', function(value){
      return this.parent.newPassword === value
    }),
    
  });

  let {
    handleSubmit,   
    reset,   
    formState: { errors },
    control,
} = useForm({
    resolver: yupResolver(schema),
});


  
  // handle login user data
  const handleUserPassword = (values) => {
    setIsLoading(true);
    if (params.enEmail) {
      values.enEmail = params.enEmail;
    }
    values.entityType = config.entityType
    return fetch('POST', `auth/changeRecoverPassword?active=true`, values)
      .then((response) => {
        if (response && response.respCode && response.respCode === 200) {
          showToasterMessage(response.respMessage, 'success');
          setReDir(true);
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
        setIsLoading(false);
        this.clearInputFields();
      }).catch((err) => {
        return err;
      });
  }

  // clear input data
  const clearInputFields = () => {
    reset({
      newPassword:'',
      confirmPassword:''
    });
  }
  const submit = (values) => {
    handleUserPassword(values);
  }
  

    return (
      <div className="col-md-6" >
        <Card title="Change Recover Password">
          
         
          <Loader loader={isLoading} />
            <form  onSubmit={handleSubmit(submit)}>
              <div className='row row-cols-12'>
              <Controller
                      name="newPassword"
                      control={control}
                      render={({ field,fieldState }) => (
                        <DoPasswordField
                        input={field}
                        id={field.name ? field.name : null}
                        name={field.name}
                        field={field}
                        fieldState={fieldState}
                        errors={errors}
                        label={'New Password'}
                        placeholder={'New Password'}
                      />
                      )}                      
                    />
                    
                    <Controller
                      name="confirmPassword"
                      control={control}
                      render={({ field ,fieldState}) => (
                        <DoPasswordField
                        input={field}
                        id={field.name ? field.name : null}
                        name={field.name}
                        field={field}
                        fieldState={fieldState}
                        errors={errors}
                        label={'Confirm Password'}
                        placeholder={'Confirm Password'}
                      />
                      )}
                      
                    />
                
               
              </div>
              <div className='mt-3 d-flex justify-content-end'>
                <Button color="primary" size='small'
                  type='submit'>Submit</Button>
                <Button style={{ marginLeft: '10px' }} type='button' color="primary" size='small'
                  onClick={() => clearInputFields()}>Reset</Button>
                <Link to={'/forms'}>
                  <Button style={{ marginLeft: '10px' }} type='button' color="primary">Back</Button>
                </Link>
              </div>
              {reDir ? <Navigate to="/log_in" /> : null}

            </form>

          

        </Card>
      </div >

    );
  }


export default ChangeRecoverPassword;